import {
  GET_ALL_ADDRESSES,
  GET_ADDRESS_BY_ID,
  GET_ADDRESS_BY_CURRENCY_ID,
} from "./walletTypes";

const initialState = {
  walletAddresses: [],
  addressByCurrency: {},
  walletAddress: null,
  defaultAddress: null,
};

const walletReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case GET_ALL_ADDRESSES:
      newState = {
        ...state,
        walletAddresses: action.payload,
      };
      break;
    case GET_ADDRESS_BY_ID:
      newState = {
        ...state,
        walletAddress: action.payload,
      };
      break;
    case GET_ADDRESS_BY_CURRENCY_ID:
      newState = {
        ...state,
        addressByCurrency: action.payload,
      };
      break;
    default:
      newState = state;
  }
  return newState;
};

export default walletReducer;
