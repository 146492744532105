import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../button';
import dataServices from '../../assets/fake-data/data-services';
import img from '../../assets/images/layout/graphic.png'
import { useTranslation } from 'react-i18next';


function Services() {
    const { t } = useTranslation();

    // const [dataBlock] = useState(
    //     {
    //         heading: 'The most trusted cryptocurrency platform.',
    //         desc: 'Cryptolly has a variety of features that make it the best place to start trading'
    //     }
    // )
    return (
        <section className="services">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="services__main">
                            {
                                dataServices.map(idx => (
                                    <div key={idx.id} className="services-box">
                                        <div className="icon">
                                            <img src={idx.icon} alt="Icon" />
                                        </div>
                                        <Link to="#" className="h6 title">{t(`dataServices.${idx.id}.title`)}</Link>
                                        <p>
                                            {t(`dataServices.${idx.id}.text`)}
                                        </p>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="block-text" data-aos="fade-up" data-aos-duration="1000">
                            <h3 className="heading">{t('sectionHead')}</h3>
                            <p className="desc">
                                {t('sectionDesc')}
                            </p>
                            <Button title={t('exchange')} path="/buy-crypto-select" />
                            <img src={img} alt="Swapify" />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;