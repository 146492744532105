import { GET_ADJUSTMENT } from "./adjustmentTypes";

const initialState = {
  adjustment: [],
};

const adjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADJUSTMENT:
      return {
        ...state,
        adjustment: action.payload,
      };
    default:
      return state;
  }
};

export default adjustmentReducer;
