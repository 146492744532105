import React, { useEffect, useState } from 'react';
import Sale01 from '../components/sale/Sale01';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { RegisterUser } from '../redux/auth/authActions'
import { getAllCountries } from '../redux/countryCode/countryCodeActions';

import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const registrationSuccess = useSelector(
        (state) => state.auth.registrationSuccess
    );
    const countryCodes = useSelector((state) => state.countryCode.countries);

    const initialUserState = {
        username: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        selectedCountry: {
            name: "",
            dial_code: "",
        },
    };

    const [user, setUser] = useState(initialUserState);
    const [errors, setErrors] = useState("");
    const [viewPass, setViewPass] = useState(0);
    const [viewCPass, setViewCPass] = useState(0);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'selectedCountry') {
            const selectedCountry = JSON.parse(value);
            setUser({
                ...user,
                selectedCountry,
            });
        } else {
            setUser({ ...user, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, email, phone, password, confirmPassword } = user;

        const regexp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (username === "") {
            setErrors("Username is required!");
        } else if (email === "") {
            setErrors("Email address is required!");
        } else if (!email.match(regexp)) {
            setErrors("Invalid email address!");
        } else if (phone === "") {
            setErrors("Phone address is required!");
        } else if (password === "") {
            setErrors("Password is required!");
        } else if (password.length < 5) {
            setErrors("Password must have at-least 6 characters!");
        } else if (password !== confirmPassword) {
            setErrors("Password and Confirm Password does not match!");
        } else {
            setErrors("");

            const data = {
                username: user.username,
                email: user.email,
                phone: user.phone,
                password: user.password,
                selectedCountryCode: user.selectedCountry.code,
            };
            dispatch(RegisterUser(data));
        }
    };

    useEffect(() => {
        dispatch(getAllCountries());
    }, [dispatch]);

    useEffect(() => {
        console.log("Registration success:", registrationSuccess);
        if (registrationSuccess) {
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }
    }, [registrationSuccess, navigate]);

    return (
        <div>

            <section className="register">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">{t('registerTo')}</h3>
                                <p className="desc fs-20">
                                    {t('registerDesc')}
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Tabs>

                                <TabList>

                                    <Tab><h6 className="fs-16">{t('email')}</h6></Tab>
                                    {/* <Tab><h6 className="fs-16">Mobile</h6></Tab> */}

                                </TabList>



                                <TabPanel>
                                    <div className="content-inner">
                                        <form>
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">{t('email')}/ID</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    name="email"
                                                    placeholder={t('placeholderEmail')}
                                                    value={user.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label
                                                >{t('password')}
                                                    {/* <span
                                                    >(8 or more characters, including numbers and special
                                                        characters)</span
                                                    > */}
                                                </label>
                                                <div className="input-with-icon">
                                                    <input
                                                        type={viewPass ? "text" : "password"}
                                                        className="form-control mb-10"
                                                        placeholder={t('placeholderPassword')}
                                                        name="password"
                                                        value={user.password}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="password-icon" onClick={() => setViewPass(!viewPass)}>
                                                        <FontAwesomeIcon style={{ color: "black", fontSize: "20px" }} icon={viewPass ? faEyeSlash : faEye} />
                                                    </span>
                                                </div>
                                                <div className="input-with-icon">
                                                    <input
                                                        type={viewCPass ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder={t('placeholderCPassword')}
                                                        name="confirmPassword"
                                                        value={user.confirmPassword}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="password-icon" onClick={() => setViewCPass(!viewCPass)}>
                                                        <FontAwesomeIcon style={{ color: "black", fontSize: "20px" }} icon={viewCPass ? faEyeSlash : faEye} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                >{t('username')}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('placeholderUsername')}
                                                    name="username"
                                                    value={user.username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{t('country')} </label>
                                                <select
                                                    className="form-control"
                                                    name="selectedCountry"
                                                    value={JSON.stringify(user.selectedCountry)}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">{t('countrySelect')}</option>
                                                    {countryCodes.map((countryCode) => (
                                                        <option key={countryCode.code} value={JSON.stringify(countryCode)}>
                                                            {countryCode.name} ({countryCode.dial_code})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                >{t('phone')}
                                                    {/* <span className="fs-14">(Enter numbers only)</span> */}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t('placeholderPhone')}
                                                    name="phone"
                                                    value={user.phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {/* 
                                            <div className="form-group">
                                                <label>UID Code </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Please enter your invitation code."
                                                />
                                            </div> */}
                                            {errors ? (
                                                <div
                                                    style={{ color: "#FE6E00" }}
                                                    className="alert alert-danger">
                                                    {" "}
                                                    {errors}{" "}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <button type="submit" className="btn-action" onClick={(e) => handleSubmit(e)}>
                                                {t('register')}
                                            </button>
                                            <div className="bottom">
                                                <p>{t('haveAccount')}</p>
                                                <Link to="/login">{t('login')}</Link>
                                            </div>
                                        </form>
                                    </div>
                                </TabPanel>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default Register;