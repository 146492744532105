import HomeTwo from "./HomeTwo";
import BuyCrypto from "./BuyCrypto";
import BuyCryptoConfirm from "./BuyCryptoConfirm";
import BuyCryptoDetails from "./BuyCryptoDetails";
import Markets from "./Markets";
import Wallet from "./Wallet";
import UserProfile from "./UserProfile";
import Login from "./Login";
import Register from "./Register";
import Contact from "./Contact";
import Faq from "./Faq";
import Terms from "./Terms";
import Privacy from "./Privacy";

const routes = [
  { path: "/", component: <HomeTwo /> },
  { path: "/buy-crypto-select", component: <BuyCrypto /> },
  { path: "/buy-crypto-confirm/:id", component: <BuyCryptoConfirm /> },
  { path: "/buy-crypto-details/:id", component: <BuyCryptoDetails /> },
  { path: "/markets", component: <Markets /> },
  { path: "/backlog", component: <Wallet /> },
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/contact", component: <Contact /> },
  { path: "/faq", component: <Faq /> },
  { path: "/regulations", component: <Terms /> },
  { path: "/privacy-policy", component: <Privacy /> },
];

export default routes;
