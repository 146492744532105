import img1 from "../images/icon/Cloud.png";
import img2 from "../images/icon/Wallet.png";
import img3 from "../images/icon/Mining.png";
import img4 from "../images/icon/Comparison.png";

const dataWork = [
  {
    id: "dataWork1",
    img: img1,
  },
  {
    id: "dataWork2",
    img: img2,
  },
  {
    id: "dataWork3",
    img: img3,
  },
  {
    id: "dataWork4",
    img: img4,
  },
];

export default dataWork;
