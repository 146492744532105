import React, { useState, useEffect, useRef } from 'react';
import { GetCoinImg } from '../../assets/coinImg/getCoinImg';

function CustomDropdown({ currencies, selectedCurrency, onCurrencyChange }) {
    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const handleDropdownClick = () => {
        setIsActive(!isActive);
    };

    const handleCurrencySelect = (currency) => {
        onCurrencyChange(currency);
        setIsActive(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="dropdown-btn" onClick={handleDropdownClick}>
                <img src={GetCoinImg(selectedCurrency)} alt="" width="24" height="24" style={{ marginRight: "10px" }} />
                {selectedCurrency}
                <span className={`fas fa-caret-${isActive ? "up" : "down"} dropdown-icon`} />
            </div>
            <div className="dropdown-content" style={{ display: isActive ? "block" : "none" }}>
                {currencies.map((currency) => (
                    <div
                        key={currency.id}
                        className="item"
                        onClick={() => handleCurrencySelect(currency.symbol)}
                    >
                        <img src={GetCoinImg(currency.symbol)} alt="" width="20" height="20" /> {currency.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CustomDropdown;


