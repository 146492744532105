import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

import img from "../../assets/images/logo/logo.png";
import img1 from "../../assets/images/logo/logo-dark.png";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  const [productLink] = useState([
    {
      id: "productLink1",
      path: "/",
    },
    {
      id: "productLink2",
      path: "/markets",
    },
    {
      id: "productLink3",
      path: "/buy-crypto-select",
    },
    {
      id: "productLink4",
      path: "/backlog",
    },
    {
      id: "productLink5",
      path: "/faq",
    },
  ]);
  const [servicesLink] = useState([
    {
      id: "servicesLink1",
      path: "/register",
    },
    {
      id: "servicesLink2",
      path: "/login",
    },
    {
      id: "servicesLink3",
      path: "/privacy-policy",
    },
    {
      id: "servicesLink4",
      path: "/regulations",
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer className="footer style-2">
      <div className="container">
        <div className="footer__main">
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="info">
                <Link to="/" className="logo light">
                  <img
                    src={img}
                    alt=""
                    style={{ width: "172px", height: "52px" }}
                  />
                </Link>
                <Link to="/" className="logo dark">
                  <img
                    src={img1}
                    alt=""
                    style={{ width: "172px", height: "52px" }}
                  />
                </Link>
                <a
                  href="https://t.me/swapifysupp"
                  target="_blank"
                  rel="noopener noreferrer">
                  <h6>
                    {t("talk")} 🤙
                    <FontAwesomeIcon className="icon-tg" icon={faTelegram} />
                  </h6>
                </a>
                <ul className="list">
                  {/* <li><p>+12 345 678 9101</p></li> */}
                  <li>
                    <p>swapify.info@swapify.boo</p>
                  </li>
                  <li>
                    <p>{t("exAddress")}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="widget">
                <div className="widget-link">
                  <h6 className="title">{t("pages")}</h6>
                  <ul>
                    {productLink.map((data, idx) => (
                      <li key={idx}>
                        <Link to={data.path}>
                          {t(`productLink.${data.id}.title`)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="widget-link s2">
                  <h6 className="title">{t("pages")}</h6>
                  <ul>
                    {servicesLink.map((data, idx) => (
                      <li key={idx}>
                        <Link to={data.path}>
                          {t(`servicesLink.${data.id}.title`)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="footer-contact">
                <h5>{t("newsletter")}</h5>
                <p>{t("newsletterDesc")}</p>
                <form action="#">
                  <input
                    type="email"
                    placeholder={t("enterEmail")}
                    required=""
                  />
                  <button type="submit" className="btn-action">
                    {t("submitButton")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="footer__bottom">
          <p>©2023 Swapify.boo. {t("allRights")}</p>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;
