import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from '../../redux/transaction/transactionActions';
import { GetCoinImg } from '../../assets/coinImg/getCoinImg';

import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';


function Coinlist01() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const transactions = useSelector((state) => state.transaction?.transactions);

    useEffect(() => {
        dispatch(getAllTransactions());
    }, [dispatch]);

    return (
        <section className="coin-list">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text">
                            <h3 className="heading">{t('latestTransactions')}</h3>
                        </div>

                        <div className="coin-list__main">
                            <Tabs>

                                {
                                    transactions.slice(0, 10).map((transaction) => (
                                        <TabPanel key={transactions.id}>
                                            <div className="content-inner">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{t('sold')}</th>
                                                            <th scope="col">{t('buy')}</th>
                                                            {/* <th scope="col">24h %</th> */}
                                                            <th scope="col">UID</th>
                                                            {/* <th scope="col">Last 7 Days</th> */}
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            transactions.slice(0, 10).map((transaction) => (
                                                                <tr key={transaction.id}>
                                                                    <td ><span className="icon-star"></span></td>
                                                                    <td>{transaction.id}</td>
                                                                    <td>
                                                                        <Link to="#">
                                                                            {/* <img src={idx.icon} alt="Swapify" /> */}
                                                                            <img src={GetCoinImg(transaction.fromCurrencyData[0]?.symbol)} alt="" width="32" height="32" />
                                                                            <span>{transaction.fromAmount}</span>
                                                                            <span className="unit">{transaction.fromCurrencyData[0]?.symbol}</span>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to="#">
                                                                            {/* <img src={idx.icon} alt="Swapify" /> */}
                                                                            <img src={GetCoinImg(transaction.toCurrencyData[0]?.symbol)} alt="" width="32" height="32" />
                                                                            <span>{transaction.convertedAmount}</span>
                                                                            <span className="unit">{transaction.toCurrencyData[0]?.symbol}</span>
                                                                        </Link>
                                                                    </td>
                                                                    <td className="boild"><span>{transaction._id}</span></td>
                                                                    {/* <td className="boild"><span>5 min ago</span></td> */}
                                                                    {/* <td className={`${idx.class}`}>{idx.sale}</td>
                                                                    <td className="boild">{idx.cap}</td>
                                                                    <td><img src={idx.chart} alt="Swapify" /></td> */}
                                                                    <td><Link to="/buy-crypto-select" className="btn">{t('trade')}</Link></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                    ))
                                }


                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Coinlist01;