import React from 'react';
import Sale01 from '../components/sale/Sale01';
import { Accordion } from 'react-bootstrap-accordion';

import dataFaq from '../assets/fake-data/data-faq'
import { useTranslation } from 'react-i18next';


function Faq() {
    const { t } = useTranslation();
    return (
        <div>

            <section className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">{t('freqass')}</h3>
                                <p className="desc fs-20">{t('howworks')}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion">
                                {
                                    Array.isArray(dataFaq) ? dataFaq.map(idx => (
                                        <Accordion key={idx.id} title={t(`faq.${idx.id}.title`)} show={idx.show}>
                                            <p className="toggle-content">{t(`faq.${idx.id}.text`)}</p>
                                        </Accordion>
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default Faq;