import { React, useEffect, useState } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";
// require("dotenv").config();

function App() {
  const [, setBackendData] = useState([{}]);

  useEffect(() => {
    fetch("/api")
      .then((response) => response.json())
      .then((data) => setBackendData(data));
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <Header />

      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}

        <Route path="*" element={<Page404 />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
