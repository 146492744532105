import { GET_ALL_COUNTRIES, GET_COUNTRY } from "./countryCodeTypes";

const initialState = {
  countries: [],
  selectedCountry: null,
};

const countryCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    default:
      return state;
  }
};

export default countryCodeReducer;
