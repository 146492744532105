import React from "react";
import { TickerTape } from 'react-tradingview-embed';

function Crypto04() {

    return (
        <div className="price-chart-img">
            <TickerTape
                className="mt-3"
                widgetProps={{
                    symbols: [
                        {
                            description: 'ETH',
                            proName: 'BINANCE:ETHUSDT',
                        },
                        {
                            description: 'BTC',
                            proName: 'BINANCE:BTCUSDT',
                        },
                        {
                            description: 'LINK',
                            proName: 'BINANCE:LINKUSDT',
                        },
                        {
                            description: 'AVAX',
                            proName: 'BINANCE:AVAXUSDT',
                        },
                        {
                            description: 'DOGE',
                            proName: 'BINANCE:DOGEUSDT',
                        },
                        {
                            description: 'BCH',
                            proName: 'BINANCE:BCHUSDT',
                        },
                        {
                            description: 'LTC',
                            proName: 'BINANCE:LTCUSDT',
                        },
                        {
                            description: 'TRX',
                            proName: 'BINANCE:TRXUSDT',
                        },
                        {
                            description: 'BNB',
                            proName: 'BINANCE:BNBUSDT',
                        },
                        {
                            description: 'ADA',
                            proName: 'BINANCE:ADAUSDT',
                        },
                        {
                            description: 'XRP',
                            proName: 'BINANCE:XRPUSDT',
                        },
                        {
                            description: 'SOL',
                            proName: 'BINANCE:SOLUSDT',
                        },
                        {
                            description: 'MATIC',
                            proName: 'BINANCE:MATICUSDT',
                        },
                        {
                            description: 'EOS',
                            proName: 'BINANCE:EOSUSDT',
                        },
                        {
                            description: 'ATOM',
                            proName: 'BINANCE:ATOMUSDT',
                        },
                        {
                            description: 'XMR',
                            proName: 'BINANCE:XMRUSDT',
                        },
                        {
                            description: 'NEO',
                            proName: 'BINANCE:NEOUSDT',
                        },
                        {
                            description: 'DASH',
                            proName: 'BINANCE:DASHUSDT',
                        },
                        {
                            description: 'ETC',
                            proName: 'BINANCE:ETCUSDT',
                        },
                    ],
                    colorTheme: 'dark',
                    showSymbolLogo: true,
                    locale: 'en',
                    isTransparent: true,
                }}
            />
        </div>
    )
}

export default Crypto04