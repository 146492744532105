import { REGISTER_USER, LOGIN_USER, LOGOUT_USER } from "./authTypes";
import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import { apiHelper } from "../apiHelper";

export const RegisterUser = (user) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/api/auth/register`, user);
    if (res?.data) {
      let { data } = res;
      ENV.removeItem("code");
      ENV.encryptUserData(data);

      dispatch({
        type: REGISTER_USER,
        payload: data,
      });
      toast.success("You are successfully registered");
    }
  } catch (error) {
    console.log(error);
    toast.error(error.response?.message);
  }
};

export const LoginUser = (user) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/api/auth/login`, user);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: LOGIN_USER,
        payload: data,
      });
      if (data.success) {
        localStorage.setItem("uToken", data.token);
        localStorage.setItem("userId", data.user.id);
        ENV.encryptUserData(data.user, data.token, data.user[0]?.userId);
      }
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const LogoutUser = (id) => async (dispatch) => {
  try {
    let userId = id.split('"').join("");
    await apiHelper("post", `/api/auth/update-logout-activity/${userId}`, "");
    dispatch({
      type: LOGOUT_USER,
    });
  } catch (error) {
    console.log(error.response.message);
  }
};
