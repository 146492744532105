import {
  GET_ALL_ADDRESSES,
  GET_ADDRESS_BY_ID,
  GET_ADDRESS_BY_CURRENCY_ID,
} from "./walletTypes";
import { apiHelper } from "../apiHelper";
import { toast } from "react-toastify";

export const getAllAddresses = () => async (dispatch) => {
  try {
    let res = await apiHelper("get", "/api/walletAddress", "");
    if (res?.data) {
      dispatch({
        type: GET_ALL_ADDRESSES,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getAddressById = (id) => async (dispatch) => {
  try {
    let res = await apiHelper("get", `/api/walletAddress/${id}`, "");
    if (res?.data) {
      dispatch({
        type: GET_ADDRESS_BY_ID,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getWalletAddressByCurrencyId =
  (currencyId) => async (dispatch) => {
    try {
      let res = await apiHelper(
        "get",
        `/api/walletAddress/currency/${currencyId}`
      );
      if (res?.data) {
        dispatch({
          type: GET_ADDRESS_BY_CURRENCY_ID,
          payload: res.data.walletAddress,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    }
  };
