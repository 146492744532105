import React, { useEffect, useState } from 'react';

import { Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction, cancelTransaction } from "../redux/transaction/transactionActions";
import QRCode from 'qrcode.react';
import { getWalletAddressByCurrencyId } from "../redux/wallet/walletActions";
import { useTranslation } from 'react-i18next';

BuyCryptoConfirm.propTypes = {

};

function BuyCryptoConfirm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const transaction = useSelector((state) => state.transaction?.transaction);
    const walletAddress = useSelector((state) => state.walletAddress?.addressByCurrency);

    useEffect(() => {
        if (id) {
            dispatch(getTransaction(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (transaction && transaction.fromCurrency && transaction.fromCurrency._id) {
            dispatch(getWalletAddressByCurrencyId(transaction.fromCurrency._id));
        }
    }, [dispatch, transaction]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (transaction && transaction._id) {
                dispatch(getTransaction(transaction._id));
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, [dispatch, transaction]);


    useEffect(() => {
        let intervalId;

        const checkTransactionStatus = async () => {
            try {
                const transactionStatus = transaction.status;
                console.log(transactionStatus);

                if (transactionStatus !== 'pending') {
                    // Redirect to /buy-crypto-details/:id if the status is not pending
                    navigate(`/buy-crypto-details/${id}`);
                    clearInterval(intervalId); // Stop checking once the status changes
                }
            } catch (error) {
                console.error('Error fetching transaction:', error);
            }
        };

        if (id) {
            // Start checking every 10 seconds
            intervalId = setInterval(checkTransactionStatus, 10000);

            // Initial check
            checkTransactionStatus();
        }

        return () => {
            // Cleanup: clear the interval when the component unmounts
            clearInterval(intervalId);
        };
    }, [dispatch, id, navigate, transaction?.status]);



    const copyToClipboard = () => {
        if (walletAddress?.address) {
            navigator.clipboard.writeText(walletAddress.address)
                .then(() => {
                    toast.success('Copied'); // this will display the toast upon success
                })
                .catch(err => {
                    toast.error('failed to copy'); // this will display the toast upon failure
                    console.error('failed to copy:', err);
                });
        }
    };

    const handleCancelTransaction = () => {
        if (transaction && transaction._id) {
            dispatch(cancelTransaction(transaction._id))
                .then(() => {
                    // Handle successful cancellation (you can show a success message)
                    toast.success('Transaction has been canceled.');
                    navigate('/buy-crypto-select')
                })
                .catch((error) => {
                    // Handle cancellation error (you can show an error message)
                    toast.error('Failed to cancel transaction.');
                    console.error('Cancellation error:', error);
                });
        }
    };

    const [dataCryptoTab] = useState([
        {
            id: 'dataCryptoTab1',
        },
        {
            id: 'dataCryptoTab2',
        },
        {
            id: 'dataCryptoTab3',
        },
    ])

    return (
        <div>

            <section className="buy-crypto s1 flat-tabs">
                <div className="container">
                    <div className="row">
                        <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <span className={`react-tabs__tab${idx.id === 'dataCryptoTab2' ? ' react-tabs__tab--selected' : ''}`} key={idx.id}>{t(`dataCryptoTab.${idx.id}.title`)}</span>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner buy-crypto__main">
                                    <div className="top">
                                        <ul className="top-list">
                                            <li className="done">
                                                <h6>
                                                    <span>
                                                        <svg
                                                            width="10"
                                                            height="8"
                                                            viewBox="0 0 10 8"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 3.99967L3.66667 6.66634L9 1.33301"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg> </span>{t('selectCurrency')}
                                                </h6>
                                            </li>
                                            <li className="active">
                                                <h6><span></span>{t('confirmPayment')}</h6>
                                            </li>
                                            <li>
                                                <h6><span></span>{t('invoice')}</h6>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="main info">
                                        <h6>{t('makePayment')}</h6>
                                        <p className="desc">
                                            {t('send')} {transaction?.fromAmount}{" "}
                                            {transaction?.fromCurrency?.symbol} in {walletAddress ? walletAddress.name : null} {t('network')}
                                        </p>
                                        {/* <h5>{walletAddress ? walletAddress.address : '糟糕，我们的货币不接受这种货币的付款'}</h5> */}

                                        <ul className="list">
                                            <li>
                                                <div className="icon">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.8333 4.16667H4.16665C3.24617 4.16667 2.49998 4.91286 2.49998 5.83333V14.1667C2.49998 15.0871 3.24617 15.8333 4.16665 15.8333H15.8333C16.7538 15.8333 17.5 15.0871 17.5 14.1667V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667ZM4.16665 2.5C2.3257 2.5 0.833313 3.99238 0.833313 5.83333V14.1667C0.833313 16.0076 2.3257 17.5 4.16665 17.5H15.8333C17.6743 17.5 19.1666 16.0076 19.1666 14.1667V5.83333C19.1666 3.99238 17.6743 2.5 15.8333 2.5H4.16665Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M10.8333 9.99967C10.8333 7.69849 12.6988 5.83301 15 5.83301H18.3333C18.7935 5.83301 19.1666 6.2061 19.1666 6.66634C19.1666 7.12658 18.7935 7.49967 18.3333 7.49967H15C13.6193 7.49967 12.5 8.61896 12.5 9.99967C12.5 11.3804 13.6193 12.4997 15 12.4997H18.3333C18.7935 12.4997 19.1666 12.8728 19.1666 13.333C19.1666 13.7932 18.7935 14.1663 18.3333 14.1663H15C12.6988 14.1663 10.8333 12.3009 10.8333 9.99967Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.8334 10.0003C15.8334 10.4606 15.4603 10.8337 15 10.8337C14.5398 10.8337 14.1667 10.4606 14.1667 10.0003C14.1667 9.54009 14.5398 9.16699 15 9.16699C15.4603 9.16699 15.8334 9.54009 15.8334 10.0003Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="content">
                                                    <p>{t('pay')}</p>
                                                    <h6 className="price">{transaction?.fromAmount}{" "}
                                                        {transaction?.fromCurrency?.symbol}</h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.8333 4.16667H4.16665C3.24617 4.16667 2.49998 4.91286 2.49998 5.83333V14.1667C2.49998 15.0871 3.24617 15.8333 4.16665 15.8333H15.8333C16.7538 15.8333 17.5 15.0871 17.5 14.1667V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667ZM4.16665 2.5C2.3257 2.5 0.833313 3.99238 0.833313 5.83333V14.1667C0.833313 16.0076 2.3257 17.5 4.16665 17.5H15.8333C17.6743 17.5 19.1666 16.0076 19.1666 14.1667V5.83333C19.1666 3.99238 17.6743 2.5 15.8333 2.5H4.16665Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M10.8333 9.99967C10.8333 7.69849 12.6988 5.83301 15 5.83301H18.3333C18.7935 5.83301 19.1666 6.2061 19.1666 6.66634C19.1666 7.12658 18.7935 7.49967 18.3333 7.49967H15C13.6193 7.49967 12.5 8.61896 12.5 9.99967C12.5 11.3804 13.6193 12.4997 15 12.4997H18.3333C18.7935 12.4997 19.1666 12.8728 19.1666 13.333C19.1666 13.7932 18.7935 14.1663 18.3333 14.1663H15C12.6988 14.1663 10.8333 12.3009 10.8333 9.99967Z"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M15.8334 10.0003C15.8334 10.4606 15.4603 10.8337 15 10.8337C14.5398 10.8337 14.1667 10.4606 14.1667 10.0003C14.1667 9.54009 14.5398 9.16699 15 9.16699C15.4603 9.16699 15.8334 9.54009 15.8334 10.0003Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="content">
                                                    <p>{t('receive')}</p>
                                                    <h6 className="price">{transaction?.convertedAmount}{" "}
                                                        {transaction?.toCurrency?.symbol}</h6>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <svg
                                                        width="24"
                                                        height="18"
                                                        viewBox="0 0 24 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.5763 17.0837C20.1786 17.0837 23.9096 13.3527 23.9096 8.75032C23.9096 4.14795 20.1786 0.416992 15.5763 0.416992C12.0556 0.416992 9.04483 2.60027 7.82408 5.68678C7.23371 5.48653 6.60104 5.37793 5.943 5.37793C2.71059 5.37793 0.09021 7.99831 0.09021 11.2307C0.09021 14.4631 2.71059 17.0835 5.943 17.0835L5.94287 17.0837H14.7016C14.7958 17.0837 14.8892 17.0766 14.9811 17.0627C15.1777 17.0766 15.3761 17.0837 15.5763 17.0837ZM10.2874 15.1905C10.2808 15.1851 10.2743 15.1797 10.2677 15.1743C10.2551 15.1882 10.2424 15.202 10.2297 15.2157L10.2874 15.1905Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="content">
                                                    <p>{t('network')}</p>
                                                    <h6 className="price">{walletAddress ? walletAddress.name : null}</h6>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="qr-code">
                                            <QRCode value={walletAddress.address} size={228} style={{
                                                display: "block",
                                                marginTop: "15px",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }} onClick={copyToClipboard} />
                                            <h5 className="my-3 wallet-address" onClick={copyToClipboard}>{walletAddress ? walletAddress.address : '糟糕，我们的货币不接受这种货币的付款'}</h5>
                                        </div>
                                    </div>
                                    <div className="main payment">
                                        <h6 className="title">{t('paymentDetails')}</h6>

                                        {/* <p className="top">Bank account</p> */}
                                        <ul className="status">
                                            <li>
                                                <p className="desc">{t('transactionUID')}</p>
                                                <p className="textId">
                                                    {transaction?._id}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('receiverAddress')}</p>
                                                <p className="text">
                                                    {transaction?.address}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('fromCur')}</p>
                                                <p className="text">
                                                    {transaction?.fromAmount}{" "}
                                                    {transaction?.fromCurrency?.symbol}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('toCur')}</p>
                                                <p className="text">
                                                    {transaction?.convertedAmount}{" "}
                                                    {transaction?.toCurrency?.symbol}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('clientEmail')}</p>
                                                <p className="text">
                                                    {transaction?.email}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                        </ul>

                                        {/* <div className="code">
                                            <h6>Reference code</h6>
                                            <p className="mb-5">
                                                You MUST include the Reference Code in your deposit in
                                                order to credit your account!
                                            </p>
                                            <p>Reference Code:</p>
                                            <div className="code-text">BLUTUKHY34PB</div>
                                        </div> */}
                                        <div className="group-button">
                                            <button className="cancel btn-action-3" onClick={handleCancelTransaction}>{t('cancel')}</button>
                                            {/* <button className="submit btn-action">Wallet</button> */}
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>


                        </Tabs>

                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default BuyCryptoConfirm;