import React from 'react';
import Sale01 from '../components/sale/Sale01';

import img from '../assets/images/layout/join.png'
import { useTranslation } from 'react-i18next';

Contact.propTypes = {

};

function Contact() {
    const { t } = useTranslation();
    return (
        <div>

            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="image">
                                <img src={img} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="contact-main">
                                <div className="block-text center">
                                    <h3 className="heading">{t('leaveMessage')}</h3>
                                    <p className="desc fs-20">{t('getInTouch')}</p>
                                </div>

                                <form>
                                    <div className="form-group">
                                        <label>{t('yourName')}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('yourNamePlaceholder')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>{t('email')} </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('placeholderEmail')}
                                        />
                                    </div>

                                    {/* <div className="form-group">
                                        <label>Subject </label>
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>NFT Items</option>
                                            <option>NFT Items 1</option>
                                            <option>NFT Items 1</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group">
                                        <label>{t('message')} </label>
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            className="form-control"
                                            placeholder={t('messagePlaceholder')}
                                        ></textarea>
                                    </div>

                                    <button type="submit" className="btn-action">{t('sendMessage')}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default Contact;