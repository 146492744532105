import Bitcoin from "../images/cryptoBrand/bitcoin.png";
import Ethereum from "../images/cryptoBrand/ethereum.png";
import Litecoin from "../images/cryptoBrand/litecoin.svg";
import EthClass from "../images/cryptoBrand/etc.svg";
import Zcash from "../images/cryptoBrand/zcash.svg";
import Chainlink from "../images/cryptoBrand/chainlink.svg";
import Monero from "../images/cryptoBrand/monero.svg";
import Polkadot from "../images/cryptoBrand/polkadot.png";
import Dash from "../images/cryptoBrand/dash.svg";
import Dogecoin from "../images/cryptoBrand/dogecoin.svg";
import Tron from "../images/cryptoBrand/tron.svg";
import Polygon from "../images/cryptoBrand/polygon.svg";
import Elrond from "../images/cryptoBrand/egld.png";
import Cardano from "../images/cryptoBrand/cardano.svg";
import SOL from "../images/cryptoBrand/solana.svg";
import Tezos from "../images/cryptoBrand/tezos.svg";
import Ripple from "../images/cryptoBrand/xrp.svg";
import Tether from "../images/cryptoBrand/usdt.svg";
import Yuan from "../images/cryptoBrand/yuan.svg";
import BNB from "../images/cryptoBrand/bnb.svg";
import Toncoin from "../images/cryptoBrand/ton.svg";
import Kakao from "../images/cryptoBrand/kakao.png";
import Avax from "../images/cryptoBrand/avax.png";
import Aptos from "../images/cryptoBrand/aptos.png";
import Cosmos from "../images/cryptoBrand/cosmos.png";
export const GetCoinImg = (symbol) => {
  switch (symbol) {
    case "ETH":
      return Ethereum;
    case "BTC":
      return Bitcoin;
    case "LTC":
      return Litecoin;
    case "ETC":
      return EthClass;
    case "ZEC":
      return Zcash;
    case "LINK":
      return Chainlink;
    case "XMR":
      return Monero;
    case "DOT":
      return Polkadot;
    case "DASH":
      return Dash;
    case "DOGE":
      return Dogecoin;
    case "TRX":
      return Tron;
    case "MATIC":
      return Polygon;
    case "EGLD":
      return Elrond;
    case "ADA":
      return Cardano;
    case "SOL":
      return SOL;
    case "XTZ":
      return Tezos;
    case "XRP":
      return Ripple;
    case "USDT":
      return Tether;
    case "CNY":
      return Yuan;
    case "BNB":
      return BNB;
    case "TON":
      return Toncoin;
    case "AVAX":
      return Avax;
    case "APT":
      return Aptos;
    case "ATOM":
      return Cosmos;
    case "KRW":
      return Kakao;
    default:
      return Kakao;
  }
};
