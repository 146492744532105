import React from 'react';
import Sale01 from '../components/sale/Sale01';
import { Accordion } from 'react-bootstrap-accordion';

import dataTerms from '../assets/fake-data/data-terms'
import { useTranslation } from 'react-i18next';



function Terms() {
    const { t } = useTranslation();
    return (
        <div>

            <section className="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="block-text center">
                                <h3 className="heading">{t('termsofuse2')}</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion">
                                {
                                    Array.isArray(dataTerms) ? dataTerms.map(idx => (
                                        <Accordion key={idx.id} title={t(`terms.${idx.id}.title`)} show={idx.show}>
                                            {
                                                Array.isArray(idx.text) ? idx.text.map((paragraph, pIdx) => (
                                                    <p key={pIdx} className="toggle-content">{t(`terms.${idx.id}.text.${pIdx}`)}</p>
                                                )) : null
                                            }
                                        </Accordion>
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default Terms;