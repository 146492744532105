import img1 from "../images/icon/manager.png";
import img2 from "../images/icon/Lock.png";
import img3 from "../images/icon/discovery.png";
const dataServices = [
  {
    id: "dataServices1",
    icon: img1,
    title: "dataServices1.title",
    text: "dataServices1.text",
  },
  {
    id: "dataServices2",
    icon: img2,
    title: "dataServices2.title",
    text: "dataServices2.text",
  },
  {
    id: "dataServices3",
    icon: img3,
    title: "dataServices3.title",
    text: "dataServices3.text",
  },
];

export default dataServices;
