import React, { useEffect, useState } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import { getCurrency, getTotalSupplyUSD, getTotalSupplyKRW } from "../redux/currency/currencyActions";
import Sale01 from '../components/sale/Sale01';
import { GetCoinImg } from '../assets/coinImg/getCoinImg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

Wallet.propTypes = {

};

function Wallet() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currencies = useSelector((state) => state.currency?.currencies);
    const totalSupplyUsd = useSelector((state) => state.currency?.totalSupplyUsd);
    const totalSupplyKrw = useSelector((state) => state.currency?.totalSupplyKrw);

    const [selectedCurrency, setSelectedCurrency] = useState('USD');

    const getUsdtValue = (symbol) => {
        const currencyUsdValue = totalSupplyUsd.find(currency => currency.symbol === symbol);
        return currencyUsdValue ? Math.floor(currencyUsdValue.usdtValue) : 'N/A';
    }

    const getKrwValue = (symbol) => {
        const currencyKrwValue = totalSupplyKrw.find(currency => currency.symbol === symbol);
        return currencyKrwValue ? Math.floor(currencyKrwValue.krwValue) : 'N/A';
    }

    const sortedCurrencies = currencies.sort((a, b) => {
        const aUsdValue = getUsdtValue(a.symbol);
        const bUsdValue = getUsdtValue(b.symbol);

        return bUsdValue - aUsdValue;
    });

    const totalSupply = Math.floor(currencies.reduce((total, currency) => {
        return total + currency.totalSupply;
    }, 0));

    const totalSupplyKr = Math.floor(currencies.reduce((total, currency) => {
        const krwValue = getKrwValue(currency.symbol);
        return total + (krwValue !== 'N/A' ? krwValue * currency.totalSupply : 0);
    }, 0));

    useEffect(() => {
        dispatch(getCurrency());
        if (selectedCurrency === 'USD') {
            dispatch(getTotalSupplyUSD());
        } else if (selectedCurrency === 'KRW') {
            dispatch(getTotalSupplyKRW());
        }
    }, [dispatch, selectedCurrency]);

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const handleTabClick = (path) => {
        if (path) navigate(path);
    }

    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'overview'
        },
        {
            id: 2,
            title: 'exchange',
            path: "/buy-crypto-select"
        },
    ])
    return (
        <div>
            <section className="wallet buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                        <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab key={idx.id} onClick={() => handleTabClick(idx.path)}>{t(idx.title)}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner">
                                    <div className="wallet-main">
                                        <h4 className="heading">{t('overview')}</h4>

                                        <div className="wallet-body">
                                            <div className="left">
                                                <p>{t('totalBalance')}</p>

                                                <div className="price">
                                                    <h6>{selectedCurrency === 'USD'
                                                        ? `$${totalSupply.toLocaleString()}`
                                                        : `₩${totalSupplyKr.toLocaleString()}`}</h6>
                                                    <div className="sale success">USD</div>
                                                </div>
                                            </div>
                                            <div className="right">
                                                <form action="/wallet">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Search" />
                                                        <svg
                                                            width="21"
                                                            height="21"
                                                            viewBox="0 0 21 21"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
                                                                stroke="#B1B5C3"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                            />
                                                        </svg>
                                                        <select className="" aria-label="USD" onChange={handleCurrencyChange} value={selectedCurrency}>
                                                            <option value="USD">USD</option>
                                                            <option value="KRW">KRW</option>
                                                        </select>
                                                    </div>
                                                    <button type="submit" className="btn-action">
                                                        {t('showBalance')}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="coin-list-wallet">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th className="center" scope="col">{t('asset')}</th>
                                                    {/* <th scope="col">Earn</th> */}
                                                    <th scope="col">{t('inOrders')}</th>
                                                    <th scope="col">{t('availableTrade')}</th>
                                                    <th scope="col">{t('totalBalance')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedCurrencies.map((currency, index) => (
                                                    <tr key={index}>
                                                        <td className="number">
                                                            <span className="boild">{index + 1}</span>
                                                        </td>
                                                        <td className="asset">
                                                            <img src={GetCoinImg(currency.symbol)} alt="" width="32" height="32" />
                                                            <p>
                                                                <span className="boild" style={{ marginLeft: "20px" }}>{currency.symbol}</span>
                                                                <span className="unit" style={{ marginLeft: "20px" }}>{currency.name}</span>
                                                            </p>
                                                        </td>
                                                        {/* <td className="color-success">
                                                            <span>7.46% APR</span>
                                                        </td> */}
                                                        <td>
                                                            <span className="boild">{currency.onOrders} {currency.symbol}</span>
                                                            <span className="unit">$0</span>
                                                        </td>
                                                        <td>
                                                            <span className="boild">{currency.totalSupply.toLocaleString()} {currency.symbol}</span>
                                                            <span className="unit">
                                                                {selectedCurrency === 'USD'
                                                                    ? `$${getUsdtValue(currency.symbol).toLocaleString()} USD`
                                                                    : `₩${getKrwValue(currency.symbol).toLocaleString()} KRW`}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="boild">{currency.totalSupply.toLocaleString()} {currency.symbol}</span>
                                                            <span className="unit">
                                                                {selectedCurrency === 'USD'
                                                                    ? `$${getUsdtValue(currency.symbol).toLocaleString()} USD`
                                                                    : `₩${getKrwValue(currency.symbol).toLocaleString()} KRW`}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </TabPanel>



                        </Tabs>

                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default Wallet;