import {
  SET_USER,
  GET_USER,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
} from "./userTypes";
import { apiHelper } from "../apiHelper";
import { toast } from "react-toastify";

export const getUser = (id) => async (dispatch) => {
  id = id || "";
  try {
    let res = await apiHelper("get", `/api/user/${id}`, "");
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: GET_USER,
        payload: data.user,
      });
      // toast.success(res.data.message)
    }
  } catch (error) {
    // console.log(error.response.message)
    // toast.error(error.response.message)
  }
};

export const updateUserProfile = (id, userData) => async (dispatch) => {
  try {
    const res = await apiHelper("put", `/api/user/${id}`, userData);
    if (res?.data) {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data,
      });
      toast.success("Profile updated successfully");
    }
  } catch (error) {
    toast.error(error.response);
  }
};

export const updateUserPassword = (id, passwordData) => async (dispatch) => {
  try {
    const res = await apiHelper(
      "put",
      `/api/user/${id}/password`,
      passwordData
    );
    if (res?.data) {
      dispatch({
        type: UPDATE_PASSWORD,
      });
      toast.success("Password updated successfully");
    }
  } catch (error) {
    toast.error(error.response);
  }
};

export const setUser = (user) => async (dispatch) => {
  try {
    let { users } = user;
    dispatch({
      type: SET_USER,
      payload: users,
    });
  } catch (error) {
    toast.error(error.response);
  }
};
