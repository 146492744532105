import React, { useState } from 'react';

import img from '../../assets/images/layout/IllustrationR.png'
import img1 from '../../assets/images/icon/discovery.png'
import img2 from '../../assets/images/icon/change.png'
import img3 from '../../assets/images/icon/adduser.png'
import { useTranslation } from 'react-i18next';
import dataAbout from '../../assets/fake-data/data-about';


function About02() {
    const { t } = useTranslation();

    const [dataTrader] = useState([
        {
            id: 1,
            icon: img1,
            numb: '58+',
            text: 'countries',
        },
        {
            id: 2,
            icon: img2,
            numb: '25+',
            text: 'tradingPairs',
        },
        {
            id: 3,
            icon: img3,
            numb: '20K+',
            text: 'transactions',
        },
    ]
    );
    return (
        <section className="about-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="about_image">
                            <img
                                className="img-main"
                                src={img}
                                alt=""
                            />

                            {
                                dataTrader.map(idx => (
                                    <div key={idx.id} className="traders-box">
                                        <div className="icon">
                                            <img src={idx.icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="numb">{idx.numb}</h6>
                                            <p>{t(idx.text)}</p>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div
                            className="about__content"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        >
                            <h3 className="heading">
                                {t('aboutHead')}
                            </h3>
                            <p className="fs-20 desc">
                                {t('aboutDesc')}
                            </p>
                            <ul className="list">

                                {
                                    dataAbout.map(idx => (
                                        <li key={idx.id}>
                                            <div className={`icon ${idx.class}`}>
                                                <img src={idx.icon} alt="" />
                                            </div>
                                            <div className="content">
                                                <h6 className="title">{t(`dataAbout.${idx.id}.title`)}</h6>
                                                <p>
                                                    {t(`dataAbout.${idx.id}.text`)}
                                                </p>
                                            </div>
                                        </li>
                                    ))
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About02;