import axios from "axios";
import { ENV } from "../config/config";
import { toast } from "react-toastify";
let baseUrl = ENV.serverUrl;

async function apiHelper(apiType, path, data) {
  if (baseUrl === undefined || !baseUrl) {
    baseUrl = "";
  }

  // Get JWT token from local storage
  const token = localStorage.getItem("uToken");

  if (
    apiType === "post" ||
    apiType === "put" ||
    apiType === "get" ||
    apiType === "delete"
  ) {
    try {
      let response = await axios({
        method: apiType,
        url: `${baseUrl + path}`,
        data,
        headers: {
          // Add the Authorization header with the JWT token
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
      return response;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
}

export { apiHelper };
