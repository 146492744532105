import React from 'react';
import line from '../../assets/images/icon/connect-line.png'

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dataWork from '../../assets/fake-data/data-work';

Work01.propTypes = {

};

function Work01() {
    const { t } = useTranslation();

    return (
        <section className="work">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h3 className="heading">{t('howItWork')}</h3>
                            <p className="fs-20 desc">
                                {t('workDesc')}
                            </p>
                        </div>

                        <div className="work__main">
                            {
                                dataWork.map(idx => (
                                    <div className="work-box" key={idx.id}>
                                        <div className="image">
                                            <img src={idx.img} alt="Swapify" />
                                        </div>
                                        <div className="content">
                                            <p className="step">{t(`dataWork.${idx.id}.step`)}</p>
                                            <Link to="/buy-crypto-select" className="title">{t(`dataWork.${idx.id}.title`)}</Link>
                                            <p className="text">{t(`dataWork.${idx.id}.text`)}</p>
                                        </div>
                                        <img
                                            className="line"
                                            src={line}
                                            alt="Swapify"
                                        />
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Work01;