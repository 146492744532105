import { toast } from "react-toastify";
import {
  GET_CURRENCY,
  TOGGLE_STATE,
  GET_TOTAL_SUPPLY_USD,
  GET_TOTAL_SUPPLY_KRW,
} from "./currencyTypes";
import { apiHelper } from "../apiHelper";

export const getCurrency = () => async (dispatch) => {
  try {
    const res = await apiHelper("get", "/api/currency/", "");
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      await dispatch({
        type: GET_CURRENCY,
        payload: data,
      });
      // await dispatch({
      //   type: TOGGLE_STATE
      // })
    }
  } catch (error) {
    // toast.error(error.response.message);
  }
};

export const getTotalSupplyUSD = (id) => async (dispatch) => {
  try {
    const res = await apiHelper(
      "get",
      `/api/currency/totalSupplyUsd/${id}`,
      ""
    );
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      await dispatch({
        type: GET_TOTAL_SUPPLY_USD,
        payload: data,
      });
    }
  } catch (error) {
    // toast.error(error.response.message);
  }
};
export const getTotalSupplyKRW = (id) => async (dispatch) => {
  try {
    const res = await apiHelper(
      "get",
      `/api/currency/totalSupplyKrw/${id}`,
      ""
    );
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      await dispatch({
        type: GET_TOTAL_SUPPLY_KRW,
        payload: data,
      });
    }
  } catch (error) {
    // toast.error(error.response.message);
  }
};

export const updateCurrencyState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
