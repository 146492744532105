import React, { useEffect, useState } from 'react';

import { Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTransaction } from '../redux/transaction/transactionActions';
import { useTranslation } from 'react-i18next';

function BuyCryptoDetails() {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();

    const transaction = useSelector((state) => state.transaction?.transaction);
    const txStatus = useSelector((state) => state.transaction.transaction?.status);

    useEffect(() => {
        if (id) {
            dispatch(getTransaction(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (transaction && transaction._id) {
                dispatch(getTransaction(transaction._id));
            }
        }, 10000);

        return () => clearInterval(intervalId);
    }, [dispatch, transaction]);

    const statusText = {
        'pending': 'Pending',
        'paid': 'Paid',
        'processing': t('processing'),
        'delivered': t('delivered'),
        'cancel': t('canceled'),
    };

    const statusStyle = {
        'pending': { color: 'yellow' },
        'paid': { color: 'blue' },
        'processing': { color: 'blue' },
        'delivered': { color: 'green' },
        'cancel': { color: 'red' },
    };

    const [dataCryptoTab] = useState([
        {
            id: 'dataCryptoTab1',
        },
        {
            id: 'dataCryptoTab2',
        },
        {
            id: 'dataCryptoTab3',
        },
    ])
    return (
        <div>

            <section className="buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                        <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <span className={`react-tabs__tab${idx.id === 'dataCryptoTab1' ? ' react-tabs__tab--selected' : ''}`} key={idx.id}>{t(`dataCryptoTab.${idx.id}.title`)}</span>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner buy-crypto__main">
                                    <div className="top">
                                        <ul className="top-list">
                                            <li className="done">
                                                <h6>
                                                    <span>
                                                        <svg
                                                            width="10"
                                                            height="8"
                                                            viewBox="0 0 10 8"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 3.99967L3.66667 6.66634L9 1.33301"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg> </span>{t('selectCurrency')}
                                                </h6>
                                            </li>
                                            <li className="done">
                                                <h6>
                                                    <span>
                                                        <svg
                                                            width="10"
                                                            height="8"
                                                            viewBox="0 0 10 8"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 3.99967L3.66667 6.66634L9 1.33301"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeMiterlimit="10"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg> </span>{t('confirmPayment')}</h6>
                                            </li>
                                            <li className="active">
                                                <h6><span></span>{t('invoice')}</h6>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="main details center">
                                        <div className="heading">
                                            {txStatus === 'delivered' && <h4>{t('success')}</h4>}
                                            {txStatus === 'cancel' && <h4 style={{ color: "red" }}>{t('canceled')}</h4>}
                                            {txStatus === 'processing' && <h4 style={{ color: "blue" }}>{t('processing')}</h4>}

                                            {txStatus === 'delivered' && (
                                                <div className="icon">
                                                    <svg
                                                        width="10"
                                                        height="8"
                                                        viewBox="0 0 10 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1 3.99967L3.66667 6.66634L9 1.33301"
                                                            stroke="white"
                                                            strokeWidth="2"
                                                            strokeMiterlimit="10"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                            {txStatus === 'processing' && (
                                                <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                                            )}
                                            {txStatus === 'cancel' && (<i class="fa fa-exclamation-circle fa-2x fa-fw" aria-hidden="true" style={{ color: "red", marginLeft: "5px" }}></i>
                                            )}

                                        </div>
                                        {txStatus === 'delivered' && (
                                            <p>
                                                {t('txSuccess')} {transaction?.convertedAmount}
                                                <span>{transaction?.toCurrency?.symbol}</span>
                                            </p>
                                        )}
                                        {txStatus === 'processing' && (
                                            <p>
                                                {t('txProcess')} {transaction?.convertedAmount} <span>{transaction?.toCurrency?.symbol}</span>
                                            </p>
                                        )}
                                        {txStatus === 'cancel' && (
                                            <p>
                                                {t('txError')}
                                            </p>
                                        )}
                                        <ul className="status">
                                            <li className="top">
                                                <p className="desc">{t('status')}</p>
                                                <p className="text" style={statusStyle[txStatus]}>{statusText[txStatus]}</p>
                                            </li>
                                            <li className="s-body">
                                                <p className="desc">{t('transactionUID')}</p>
                                                <p className="textId">{transaction?._id}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="main payment">
                                        <h6 className="title">{t('paymentDetails')}</h6>

                                        {/* <p className="top">Bank account</p> */}
                                        <ul className="status">
                                            <li>
                                                <p className="desc">{t('transactionUID')}</p>
                                                <p className="textId">
                                                    {transaction?._id}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('receiverAddress')}</p>
                                                <p className="text">
                                                    {transaction?.address}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('fromCur')}</p>
                                                <p className="text">
                                                    {transaction?.fromAmount}{" "}
                                                    {transaction?.fromCurrency?.symbol}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('toCur')}</p>
                                                <p className="text">
                                                    {transaction?.convertedAmount}{" "}
                                                    {transaction?.toCurrency?.symbol}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                            <li>
                                                <p className="desc">{t('clientEmail')}</p>
                                                <p className="text">
                                                    {transaction?.email}<svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9 20C11.7614 20 14 17.7614 14 15C14 12.2386 11.7614 10 9 10C6.23858 10 4 12.2386 4 15C4 17.7614 6.23858 20 9 20ZM9 22C12.866 22 16 18.866 16 15C16 11.134 12.866 8 9 8C5.13401 8 2 11.134 2 15C2 18.866 5.13401 22 9 22Z"
                                                            fill="#777E90"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M15.0001 4C13.5193 4 12.1899 4.6424 11.273 5.66691C10.9047 6.07844 10.2725 6.11346 9.86093 5.74513C9.4494 5.37681 9.41438 4.74461 9.78271 4.33309C11.063 2.9026 12.9268 2 15.0001 2C18.866 2 22.0001 5.13401 22.0001 9C22.0001 11.0733 21.0975 12.937 19.667 14.2173C19.2554 14.5857 18.6232 14.5507 18.2549 14.1391C17.8866 13.7276 17.9216 13.0954 18.3331 12.7271C19.3577 11.8101 20.0001 10.4807 20.0001 9C20.0001 6.23858 17.7615 4 15.0001 4Z"
                                                            fill="#777E90"
                                                        />
                                                    </svg>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </section>

            <Sale01 />

        </div>
    );
}

export default BuyCryptoDetails;