import React, { useEffect, useState } from 'react';
import { CryptocurrencyMarket } from 'react-tradingview-embed';
import Crypto04 from '../components/crypto/Crypto04';
import Blog from '../components/blog/Blog';
import axios from 'axios';
import 'react-tabs/style/react-tabs.css';

import img from '../assets/images/layout/banner4.png'
import dataBlog from '../assets/fake-data/data-blog';
import { useTranslation } from 'react-i18next';



function Markets() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const [fearAndGreedIndex, setFearAndGreedIndex] = useState(null);
    const [locale, setLocale] = useState('en');

    useEffect(() => {
        axios.get('https://api.alternative.me/fng/')
            .then(response => {
                if (response.data && response.data.data) {
                    setFearAndGreedIndex(response.data.data[0].value);
                }
            })
            .catch(error => {
                console.error('Error fetching Fear and Greed Index:', error);
            });
    }, []);
    useEffect(() => {
        const updateLocale = () => {
            setLocale(i18n.language || 'en');
        };

        i18n.on('languageChanged', updateLocale);

        return () => {
            i18n.off('languageChanged', updateLocale);
        };
    }, [i18n]);
    const fearAndGreedStyles = {
        indexBar: {
            position: 'relative',
            height: '30px',
            backgroundColor: '#e0e0e0',
            borderRadius: '15px',
        },
        indexLevel: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: `${fearAndGreedIndex || 0}%`, // Set width dynamically
            backgroundImage: 'linear-gradient(to right, #ff5722, #4caf50)',
            borderRadius: 'inherit',
        },
    };
    return (
        <div className='markets home-2'>

            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__content">
                                <div style={{ border: '2px solid darkslategray', padding: '30px', paddingBottom: '80px' }}>
                                    <h1>{t('indexFearGreed')}</h1>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px', fontSize: '18px' }}>
                                        <span style={{ color: '#ff5722' }}>{t('fear')}</span>
                                        <span style={{ color: '#4caf50' }}>{t('greed')}</span>
                                    </div>
                                    <div style={fearAndGreedStyles.indexBar}>
                                        <div style={fearAndGreedStyles.indexLevel}></div>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <h1>
                                            {t('todayIndex')}: {fearAndGreedIndex || '.'}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__image">
                                <img src={img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Crypto04 />


            <section className="coin-list">
                <div className="container">
                    <div className="row">
                        <div className="market-bg">
                            <CryptocurrencyMarket
                                className="mt-3"
                                widgetProps={{
                                    width: '100%',
                                    height: '490',
                                    defaultColumn: 'moving_averages',
                                    screener_type: 'crypto_mkt',
                                    displayCurrency: 'USD',
                                    locale: locale,
                                    isTransparent: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>


            <Blog data={dataBlog} />
        </div>
    );
}

export default Markets;