import authReducer from "./auth/authReducer";
import userReducer from "./user/userReducer";
import currencyReducer from "./currency/currencyReducer";
import transactionReducer from "./transaction/transactionReducer";
import walletReducer from "./wallet/walletReducer";
import adjustmentReducer from "./adjustment/adjustmentReducer";
import countryCodeReducer from "./countryCode/countryCodeReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const middleware = [thunk];

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  currency: currencyReducer,
  transaction: transactionReducer,
  walletAddress: walletReducer,
  adjustment: adjustmentReducer,
  countryCode: countryCodeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
