const dataTerms = [
  {
    id: "term1",
    text: [
      "1.1. You agree and acknowledge the following. You have reached the legal age to accept this Terms of Use. You have not been suspended or prohibited from using the service before.",
      "1.2. If you use the service on behalf of a corporation, you also state and guarantee the following. That entity is duly registered and acts according to its current laws. It is in the jurisdiction of corporate establishment and has granted you the authority to act on its behalf.",
    ],
    show: "show",
  },
  {
    id: "term2",
    text: [
      "2.1. Swapify는 사용자에게 교환 기능을 제공하는 시스템의 상표입니다. 디지털 통화.",
      "2.2. 서비스 - 디지털 및/또는 전자 화폐의 교환, 판매 및 구매를 위한 인터넷 서비스를 제공하는 시스템입니다.",
      "2.3. 사용자는 Swapify 서비스를 사용하는 개인입니다.",
      "2.4. 디지털 통화 - 비트코인, 라이트코인, 이더리움 및 기타 블록체인 기반 통화.",
      "2.5. 결제 시스템은 다음을 대표하는 제3자가 개발한 소프트웨어 및 하드웨어 제품입니다. 금전적 의무를 설명하고 상호 합의를 조직하는 메커니즘입니다. 사용자에 의해.",
      "2.6. 서비스 - 구매, 판매 및 개인 간 P2P 거래 수행 지원 디지털 통화 교환 및 기타 서비스에 대한 정보가 서비스 창에 게시됩니다.",
      "2.7. 결제는 사용자로부터 사용자 또는 서비스로 디지털 통화 또는 법정화폐 자금을 이체하는 것입니다. 반대 방향.",
      "2.8. 신청 - 서비스가 제공하는 서비스 중 하나를 이용하고자 하는 사용자의 의사를 작성하여 표시하는 것 계약에 설명되고 매개변수에 지정된 조건에 따라 서비스 웹사이트를 통한 전자 양식 이 부록의.",
      "2.9. 파트너란 사용자 유치를 위한 서비스를 서비스에 제공하는 사람으로, 그 조건은 다음과 같습니다. 본 계약에서.",
    ],
  },
  {
    id: "term3",
    text: [
      "3.1. Swapify 사이트는 개인적인 용도로만 사용됩니다. 사이트에 등록함으로써 귀하는 다음과 같은 행위를 하지 않을 것에 동의합니다. 자신의 계정이 아닌 다른 계정을 사용하고 승인되지 않은 계정을 얻으려고 시도하지 않습니다. 다른 사용자의 계정에 액세스합니다. ",
      "3.2. Swapify 관리팀은 제공된 정보를 추가로 확인하고 요청할 권리가 있습니다. 교환 관련 필수 서류 : 여권 사진 또는 기타 신분증 은행 카드(기밀 데이터 표시 없음) 자금 이체를 위한 현재 전화번호 영수증 및 기타 필요한 데이터. 음성인증도 가능합니다. (전화나 접속 가능한 메신저를 통해) 현재 전화번호와 연결됨). 서류 제출 조건이 충족되지 않는 경우 서비스는 거부할 권리가 있습니다. 서비스 제공을 중단하고 발송인의 계좌정보로 환불을 시작합니다. 반품 절차는 영업일 기준 최대 5일이 소요됩니다.",
      "3.3. Swapify 서비스는 사기를 방지하기 위해 거래를 중단하고 사용자의 자금을 보유할 권리가 있습니다. 필요한 기간 동안 서비스 또는 사용자의 재정적, 명예적 손실을 초래할 수 있는 행위 금융 조사를 수행하고 지불 게이트웨이 또는 발행자의 은행으로부터 거래 데이터를 얻기 위해.",
      "3.4. 귀하의 계정의 기밀성. 귀하는 엄격한 규정을 준수할 책임이 있음을 인정합니다. 비밀번호를 포함한 계정 정보를 기밀로 유지하고 디지털 통화를 보호합니다. 귀하의 계정에 게시된 모든 활동 및 거래에 대해. 귀하는 귀하와의 타협이 무엇이든 이해합니다. 로그인 정보는 귀하의 계정을 제3자의 무단 접근에 노출시킬 수 있으며, 이로 인해 연결된 은행 계좌와 같은 연결된 계좌를 포함하여 귀하의 계정에서 디지털 통화 또는 자금의 손실 또는 도난.",
      "3.5. 제3자에 대한 책임 귀하의 계정은 개인적인 용도로만 사용되며 악용할 수 없습니다. 또는 제3자에 의한 액세스. 어떠한 경우에도 귀하는 모든 작위 또는 부작위에 대해 전적인 책임을 집니다. 귀하의 계정에 액세스 및/또는 사용하는 제3자.",
      "3.6. 귀하는 범죄 행위를 포함하되 이에 국한되지 않는 범죄 행위에 가담하기 위해 서비스를 사용하지 않을 것에 동의합니다. 자금 세탁, 불법 도박, 테러 자금 조달. 또한 귀하는 방법을 사용하지 않을 것을 보증합니다. 귀하가 사이트에 액세스하는 위치를 숨기기 위해.",
    ],
  },
  {
    id: "term4",
    text: [
      "4.1. Swapify 서비스 주문은 사용자가 Swapify 인터넷 사이트를 통해 해당 애플리케이션을 전송함으로써 수행됩니다.",
      "4.2. 서비스를 이용함으로써 사용자는 자신이 지폐를 법적으로 소유하고 관리하고 있음을 확인합니다. 해당 결제와 관련된 전자 및/또는 디지털 화폐.",
      "4.3. Swapify 서비스는 다음과 같은 서비스를 제공합니다. 사용자에게 실시간으로 다른 사용자에게 전자 및/또는 디지털 또는 법정화폐를 판매할 수 있는 기회를 제공하는 서비스입니다. 사용자 간에 전자화폐와 디지털화폐를 상호 교환할 수 있는 기회를 제공하는 서비스입니다. 고객과 교환 서비스의 내부 준비금 간에 전자 및 디지털 통화를 상호 교환할 수 있는 기회를 사용자에게 제공하는 서비스입니다.",
      "4.4. 신청서를 작성함으로써 사용자는 지시하고 서비스는 사용자를 대신하여 사용자의 비용으로 디지털 및/또는 전자 제품의 판매 및 전송을 위한 조치를 수행합니다. 또는 법정화폐를 다른 사용자에게 양도할 수 있습니다.",
      "4.5. 사용자로부터 자금을 수령한 순간부터 규칙에 따라 할당된 시간(교환 방향에 따라) 동안 관련 조항에 지정된 금액 응용 프로그램, 서비스는 수신된 전자 또는 법정화폐를 사용자가 응용 프로그램에서 지정한 데이터 및 금액으로 전송(전송)할 의무가 있습니다. 불가항력적인 상황.",
      "4.6. 서비스는 해당 애플리케이션에 대한 결제가 이루어지지 않은 경우 디지털 및/또는 전자 또는 법정화폐 구매를 위해 사용자가 생성한 애플리케이션을 취소할 권리가 있습니다. 해당 애플리케이션이 생성된 후 30분 이내에 해당 서비스의 현재 계정으로 수신됩니다.",
      "4.7. 수신된 디지털 및/또는 전자 또는 법정화폐를 사용자에게 이체(이체)해야 하는 서비스의 의무는 인출 시점에 이행된 것으로 간주됩니다. 해당 결제 시스템의 거래 내역에 입력된 서비스 계정의 해당 결제 시스템의 디지털 및/또는 전자 또는 법정화폐.",
    ],
  },
  {
    id: "term5",
    text: [
      "5.1. 사용자는 등록 시 제공한 정보와 데이터의 정확성과 완전성에 대한 책임이 있습니다. 이용자가 개인정보를 잘못 입력한 경우 응용 프로그램 실행을 위해 잘못된 데이터를 제공했거나 Swapify 서비스는 그러한 오류로 인해 사용자에게 발생한 손실에 대해 책임을 지지 않습니다.",
      "5.2. 이러한 조건에 동의하는 경우 사용자는 다음 사항을 솔직하게 선언하고 확인합니다.",
      "5.3. 그는 자신에 대해 정확하고 진실된 정보를 제공했습니다.",
      "5.4. 이들은 자금세탁 거래나 활동에 관여하지 않습니다.",
      "5.5. 그의 소득은 범죄 및/또는 테러 활동과 관련이 없습니다.",
      "5.6. 그의 소득은 다른 불법 활동과 관련이 없습니다.",
      "5.7. 사용자는 소프트웨어나 하드웨어를 방해하거나 서비스에 전송된 매개변수를 왜곡함으로써 Swapify 서비스의 운영을 방해하지 않을 것을 약속합니다.",
      "5.8. 불가항력이나 예상치 못한 사건으로 인해 본 약관을 준수하지 못한 부분적 또는 전체적 실패에 대해 사용자는 책임을 지지 않습니다. 또는 적절한 조치로 예방할 수 있습니다.",
    ],
  },
  {
    id: "term6",
    text: [
      "6.1. Swapify 서비스는 서비스 웹사이트에 변경 사항을 게시하여 계약을 일방적으로 변경할 권리가 있습니다. 변경사항은 그 순간부터 적용됩니다. 단, 변경 사항에 대한 다른 유효 날짜가 게시 시 추가로 결정되지 않는 한.",
      "6.2. Swapify는 사용자가 지정한 이메일을 통해 교환 프로세스 상태에 대한 정보 및 기타 정보를 사용자에게 보낼 권리가 있습니다.",
      "6.3. 모든 그래픽, 텍스트 정보, 프로그램 코드 등을 포함하여 사이트에 포함된 정보는 Swapify의 자산이며 저작권법에 의해 보호됩니다.",
      "6.4. 사용자는 본 약관의 모든 조항을 숙지하고 이를 완전히 수락함을 확인합니다.",
    ],
  },
];

export default dataTerms;
