import {
  GET_TRANSACTION,
  ADD_TRANSACTION,
  CLEAR_TRANSACTION,
  CANCEL_TRANSACTION,
  CONFIRM_TRANSACTION,
  GET_ALL_TRANSACTIONS,
  GET_USER_TRANSACTIONS,
  GET_USER_TRANSACTION_TOTAL,
} from "./transcationTypes";
import { apiHelper } from "../apiHelper";
import { toast } from "react-toastify";

export const getTransaction = (id) => async (dispatch) => {
  try {
    let res = await apiHelper("get", `/api/transaction/${id}`, "");
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_TRANSACTION,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUserTransactions = (userId) => async (dispatch) => {
  try {
    let res = await apiHelper("get", `/api/transaction/user/${userId}`, "");
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: GET_USER_TRANSACTIONS,
        payload: data,
      });
    }
  } catch (error) {
    // toast.error(error.response.message);
  }
};

export const getUserTransactionTotal = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper(
      "get",
      `/api/transaction/${userId}/totalUSD`,
      ""
    );
    dispatch({
      type: GET_USER_TRANSACTION_TOTAL,
      payload: res.data.totalUSD,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAllTransactions = () => async (dispatch) => {
  try {
    let res = await apiHelper("get", "/api/transaction/delivered", "");
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: GET_ALL_TRANSACTIONS,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addTransaction = (data) => async (dispatch) => {
  try {
    let res = await apiHelper("post", `/api/transaction/add`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message);
      dispatch({
        type: ADD_TRANSACTION,
        payload: data.transaction,
      });
      return data.transaction._id;
    }
  } catch (error) {
    // console.log(error.response.message)
    toast.error(error.response.message);
    return null;
  }
};

export const cancelTransaction = (transactionId) => {
  return async (dispatch) => {
    try {
      const res = await apiHelper("put", `/api/transaction/${transactionId}`, {
        status: "cancel",
      });

      dispatch({
        type: CANCEL_TRANSACTION,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const confirmTransaction = (transactionId) => {
  return async (dispatch) => {
    try {
      const res = await apiHelper("put", `/api/transaction/${transactionId}`, {
        status: "paid",
      });

      dispatch({
        type: CONFIRM_TRANSACTION,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const ClearData = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_TRANSACTION,
    });
  } catch (error) {
    // console.log(error.response.message)
    toast.error(error.response.message);
  }
};
