import {
  GET_TRANSACTION,
  GET_ALL_TRANSACTIONS,
  ADD_TRANSACTION,
  CLEAR_TRANSACTION,
  CANCEL_TRANSACTION,
  CONFIRM_TRANSACTION,
  GET_USER_TRANSACTIONS,
  GET_USER_TRANSACTION_TOTAL,
} from "./transcationTypes";

const initialState = {
  transactions: [],
  transaction: null,
  totalUSD: null,
  currentTransaction: null,
  success: false,
};

const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    case GET_USER_TRANSACTION_TOTAL:
      return {
        ...state,
        totalUSD: action.payload,
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        currentTransaction: action.payload._id,
        success: true,
      };
    case CLEAR_TRANSACTION:
      return {
        ...state,
        transactions: [],
        transaction: [],
        success: false,
      };
    case CANCEL_TRANSACTION:
      if (state.transaction?._id === action.payload?._id) {
        return {
          ...state,
          transaction: action.payload,
        };
      } else {
        return state;
      }
    case CONFIRM_TRANSACTION:
      if (state.transaction?._id === action.payload?._id) {
        return {
          ...state,
          transaction: action.payload,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default TransactionReducer;
