import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import "./i18n";
import ScrollToTop from "./ScrollToTop";
import store from "./redux/combineReducer";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer style={{ zIndex: 999999 }} />
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
