import img1 from "../images/icon/show.png";
import img2 from "../images/icon/done.png";
import img3 from "../images/icon/users.png";
const dataAbout = [
  {
    id: "dataAbout1",
    icon: img1,
  },
  {
    id: "dataAbout2",
    icon: img2,
    class: "green",
  },
  {
    id: "dataAbout3",
    icon: img3,
    class: "blue",
  },
];

export default dataAbout;
