import { GET_ALL_COUNTRIES, GET_COUNTRY } from "./countryCodeTypes";
import { apiHelper } from "../apiHelper";

export const getAllCountries = () => async (dispatch) => {
  try {
    const res = await apiHelper("get", "/api/country/list", "");
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_ALL_COUNTRIES,
        payload: data,
      });
    }
  } catch (error) {
    // Handle error, e.g., toast.error(error.response.message);
  }
};

export const getCountryById = (id) => async (dispatch) => {
  try {
    const res = await apiHelper("get", `/api/country/${id}`, "");
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_COUNTRY,
        payload: data,
      });
    }
  } catch (error) {
    // Handle error, e.g., toast.error(error.response.message);
  }
};
