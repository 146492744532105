import { toast } from "react-toastify";
import { GET_ADJUSTMENT } from "./adjustmentTypes";
import { apiHelper } from "../apiHelper";

export const getAllAdjustments = () => async (dispatch) => {
  try {
    const res = await apiHelper("get", "/api/percentageAdjustment/", "");
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_ADJUSTMENT,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
