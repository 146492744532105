import React from 'react';
import Button from '../button';

import img from '../../assets/images/layout/banner2.png'
import { useTranslation } from 'react-i18next';

Banner02.propTypes = {

};

function Banner02() {
    const { t } = useTranslation();
    // const [dataBlock] = useState(
    //     {
    //         heading: t('bannerHead'),
    //         desc: t('bannerDesc'),
    //     }
    // );
    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                            <h2 className="title">
                                {t('bannerHead')}
                            </h2>
                            <p className="fs-20 desc">
                                {t('bannerDesc')}
                            </p>
                            <Button title={t('exchangeNow')} path='/buy-crypto-select' />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                            <img src={img} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner02;