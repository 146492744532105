import img1 from "../images/icon/show.png";
import img2 from "../images/icon/done.png";
import img3 from "../images/icon/users.png";
const dataAbout = [
  {
    id: "faq1",
    show: "show",
  },
  {
    id: "faq2",
  },
  {
    id: "faq3",
  },
  {
    id: "faq4",
  },
  {
    id: "faq5",
  },
  {
    id: "faq6",
  },
  {
    id: "faq7",
  },
  {
    id: "faq8",
  },
  {
    id: "faq9",
  },
  {
    id: "faq10",
  },
];

export default dataAbout;
