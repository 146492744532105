import {
  GET_CURRENCY,
  TOGGLE_STATE,
  GET_TOTAL_SUPPLY_USD,
  GET_TOTAL_SUPPLY_KRW,
} from "./currencyTypes";

const initialState = {
  currencies: [],
  currenciesFetched: false,
  totalSupplyUsd: [],
  totalSupplyKrw: [],
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return {
        ...state,
        currencies: action.payload,
        currenciesFetched: true,
      };
    case GET_TOTAL_SUPPLY_USD:
      return {
        ...state,
        totalSupplyUsd: action.payload,
      };
    case GET_TOTAL_SUPPLY_KRW:
      return {
        ...state,
        totalSupplyKrw: action.payload,
      };
    case TOGGLE_STATE:
      return {
        ...state,
        currenciesFetched: false,
      };
    default:
      return state;
  }
};

export default currencyReducer;
