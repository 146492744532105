import {
  SET_USER,
  GET_USER,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
} from "./userTypes";

const initialState = {
  user: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default userReducer;
