const dataPrivacy = [
  {
    id: "dataPrivacy1",
    show: "show",
  },
  {
    id: "dataPrivacy2",
  },
  {
    id: "dataPrivacy3",
  },
  {
    id: "dataPrivacy4",
  },
  {
    id: "dataPrivacy5",
  },
];

export default dataPrivacy;
