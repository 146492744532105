import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

Sale01.propTypes = {

};

function Sale01(props) {
    const { t } = useTranslation();

    return (
        <section className="section-sale">
            <div className="container">
                <div className="row align-item-center">
                    <div className="col-md-7">
                        <div className="block-text">
                            <h4 className="heading">{t('sale1')}</h4>
                            <p className="desc">
                                {t('sale2')}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="button">
                            <Link to="/register">{t('createAccount')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sale01;