const menus = [
  // {
  //   id: 1,
  //   name: "Home",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Home 01",
  //       links: "/",
  //     },
  //     {
  //       id: 2,
  //       sub: "Home 02",
  //       links: "/home-v2",
  //     },
  //     {
  //       id: 3,
  //       sub: "Home 03",
  //       links: "/home-v3",
  //     },
  //   ],
  // },
  {
    id: 2,
    name: "buyCrypto",
    links: "/buy-crypto-select",
  },
  {
    id: 3,
    name: "market",
    links: "/markets",
  },
  {
    id: 6,
    name: "backlog",
    links: "/backlog",
  },

  {
    id: 7,
    name: "Pages",
    links: "#",
    namesub: [
      // {
      //   id: 1,
      //   sub: "User Profile",
      //   links: "/user-profile",
      // },
      {
        id: 2,
        sub: "Login",
        links: "/login",
      },
      {
        id: 3,
        sub: "Register",
        links: "/register",
      },
      {
        id: 4,
        sub: "Contact",
        links: "/contact",
      },
      {
        id: 5,
        sub: "FAQ",
        links: "/faq",
      },
    ],
  },
];

export default menus;
